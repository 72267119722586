import { Component, OnInit } from "@angular/core";

import { Gallery } from "../../models/gallery.model";
import galleries from "../../../assets/json/galleries.json";
import { Title } from "@angular/platform-browser";
import { GalleriesService } from "src/app/services/galleries.service";

@Component({
  selector: "app-gallery-page",
  templateUrl: "./gallery-page.component.html",
  styleUrls: ["./gallery-page.component.css"],
})
export class GalleryPageComponent implements OnInit {
  galleries: Gallery[];
  isLoading: boolean;

  constructor(
    private titleService: Title,
    private galleriesService: GalleriesService
  ) {
    titleService.setTitle("Trautsons - Galéria");
    // this.galleries = galleries;
    this.isLoading = true;
  }

  ngOnInit() {
    this.getGalleries();
  }

  getGalleries(): void {
    this.isLoading = true;
    this.galleriesService.getGalleries().subscribe((galleries: Gallery[]) => {
      this.galleries = galleries;
      this.isLoading = false;
    });
  }
}
