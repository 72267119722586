import { Component, OnInit } from "@angular/core";
import { EventsService } from "src/app/services/events.service";

import { Event } from "../../../models/event.model";

@Component({
  selector: "app-fb-events",
  templateUrl: "./fb-events.component.html",
  styleUrls: ["./fb-events.component.css"],
})
export class FbEventsComponent implements OnInit {
  events: Event[];
  earliestEvents: Event[] = [];
  isLoading: boolean;

  constructor(private eventsService: EventsService) {
    this.isLoading = true;

    // this.events = this.bubbleSort(events);

    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    // for (let i = 0; i < this.events.length; i++) {
    //   const date = new Date(this.events[i].date);
    //   if (date < yesterday) {
    //     this.events.splice(i, 1);
    //     i--;
    //   }
    // }
  }

  ngOnInit() {
    this.getEvents();
  }

  getEvents(): void {
    this.isLoading = true;
    this.eventsService.getEvents().subscribe((events: Event[]) => {
      this.events = events;
      let editedEvents = new Array();
      this.events.forEach((event) => {
        if (event.eventDate.getTime() >= new Date().getTime()) {
          editedEvents.push(event);
        }
      });
      if (editedEvents.length >= 3) {
        for (let i = 0; i < 3; i++) {
          this.earliestEvents.push(editedEvents[i]);
        }
      } else {
        for (let i = 0; i < editedEvents.length; i++) {
          this.earliestEvents.push(editedEvents[i]);
        }
      }

      this.earliestEvents.forEach((event) => {
        event.description = event.description.replace(/<[^>]+>/g, "").trim();
      });
      this.isLoading = false;
    });
  }

  // bubbleSort(arr) {
  //   const n = arr.length;
  //   for (let i = 0; i < n - 1; i++) {
  //     for (let j = 0; j < n - i - 1; j++) {
  //       if (arr[j].date > arr[j + 1].date) {
  //         [arr[j], arr[j + 1]] = this.swap([arr[j], arr[j + 1]]);
  //       }
  //     }
  //   }
  //   return arr;
  // }

  // swap([element1, element2]) {
  //   const tmp = element1;
  //   element1 = element2;
  //   element2 = tmp;

  //   return [element1, element2];
  // }
}
