import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Member } from "../../../models/member.model";
// import members from '../../../../assets/json/members.json';
import { Title } from "@angular/platform-browser";
import { MembersService } from "src/app/services/members.service";

@Component({
  selector: "app-about-us-single",
  templateUrl: "./about-us-single.component.html",
  styleUrls: ["./about-us-single.component.css"],
})
export class AboutUsSingleComponent implements OnInit {
  member: Member;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private memberService: MembersService
  ) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.getMember(params.get("url"));
    });
  }

  getMember(id: string): void {
    this.isLoading = true;
    this.memberService.getMember(id).subscribe((member: Member[]) => {
      if (member.length === 0) {
        this.router.navigate(["/404"]);
      }
      this.member = member[0];
      this.titleService.setTitle("Trautsons - " + this.member.title);

      this.isLoading = false;
    });
  }
}
