import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { Article } from "../models/article.model";

@Injectable({
  providedIn: "root",
})
export class ArticlesService {
  collectionName: string;

  constructor(private firestore: AngularFirestore) {
    this.collectionName = "articles";
  }

  getArticles() {
    return this.firestore
      .collection(this.collectionName, (ref) => {
        return ref.where("status", "==", "PUBLISHED");
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes
            .map((a) => {
              const data = a.payload.doc.data() as Article;
              data.date = data.date.toDate();
              data.id = a.payload.doc.id;
              return data;
            })
            .filter((task) => {
              return !task.isRemoved;
            })
            .sort((a: Article, b: Article) => {
              const date1 = new Date(a.date);
              const date2 = new Date(b.date);
              return date2.getTime() - date1.getTime();
            });
        })
      );
  }

  getArticle(url: string) {
    return this.firestore
      .collection(this.collectionName, (ref) => {
        return ref.where("url", "==", url);
      })
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes
            .map((a) => {
              const data = a.payload.doc.data() as Article;
              // data.date = data.date.toDate();
              data.id = a.payload.doc.id;
              return data;
            })
            .filter((task) => {
              return !task.isRemoved;
            })
            .sort((a: Article, b: Article) => {
              const date1 = new Date(a.date);
              const date2 = new Date(b.date);
              return date2.getTime() - date1.getTime();
            });
        })
      );
  }
}
