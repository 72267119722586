import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Trautsons';

  onActivate(event) {
    window.scroll(0, 0);

    // const scrollDown = window.setInterval(() => {
    //   const pos = window.pageYOffset;
    //   if (pos < 700) {
    //     window.scrollTo(0, pos + 20);
    //   } else {
    //     window.clearInterval(scrollDown);
    //   }
    // }, 5);
  }

}
