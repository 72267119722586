import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements OnInit {

  @Input() bgName = 'about-us';
  @Input() h1Name = 'Trautsons Bor és Kultúráért Egyesület';

  constructor() { }

  ngOnInit() {
  }

}
