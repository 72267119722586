import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { Ticket } from '../../../models/ticket.model';
import { TicketService } from '../../../services/ticket.service';

@Component({
  selector: 'app-ticket-usage',
  templateUrl: './ticket-usage.component.html',
  styleUrls: ['./ticket-usage.component.css']
})
export class TicketUsageComponent implements OnInit {

  isAdmin: boolean;
  password: string;
  PSW = 'traubi';
  tickets: Ticket[];
  currTicket: Ticket;
  thereIsNoTicket: boolean;
  isLoading: boolean;
  isUsedTicket: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ticketsService: TicketService,
    private cookieService: CookieService
  ) {
    if (this.cookieService.get('password') === this.PSW) {
      this.isAdmin = true;
      this.search();
    } else {
      this.isAdmin = false;
    }
  }

  login() {
    if (this.password === this.PSW) {
      this.cookieService.set('password', this.PSW);
      this.isAdmin = true;
      this.search();
    }
  }

  search() {
    this.isLoading = true;
    this.ticketsService.getTickets().subscribe(tickets => {
      this.tickets = tickets;
      this.route.paramMap.subscribe(params => {
        this.tickets.forEach(ticket => {
          if (this.thereIsNoTicket) {
            if (ticket.generatedCode === params.get('url')) {
              console.log('Megvan');
              this.currTicket = ticket;
              this.isUsedTicket = this.currTicket.isUsed;
              this.thereIsNoTicket = false;
              if (ticket.isPayed) {
                this.ticketsService.setToUsed(ticket);
              }
            } else {
              console.log('Nincs meg');
            }
          }
        });
      });
      this.isLoading = false;
    });
    this.thereIsNoTicket = true;
  }

  ngOnInit() {

  }

}
