import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { Event } from "../models/event.model";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  collectionName: string;

  constructor(private firestore: AngularFirestore) {
    this.collectionName = "events";
  }

  getEvents() {
    return (
      this.firestore
        // .collection(this.collectionName)
        .collection(this.collectionName, (ref) => {
          return ref.where("status", "==", "PUBLISHED");
        })
        .snapshotChanges()
        .pipe(
          map((changes: any[]) => {
            return changes
              .map((a) => {
                const data = a.payload.doc.data() as Event;
                data.id = a.payload.doc.id;
                data.eventDate = data.eventDate.toDate();
                data.date = data.date.toDate();
                return data;
              })
              .sort((a: Event, b: Event) => {
                return a.eventDate - b.eventDate;
              });
          })
        )
    );
  }

  getEvent(id: string) {
    return this.firestore
      .collection(this.collectionName, (ref) => {
        return ref.where("url", "==", id).limit(1);
      })
      .snapshotChanges()
      .pipe(
        map((changes: any[]) => {
          return changes
            .map((a) => {
              const data = a.payload.doc.data() as Event;
              data.id = a.payload.doc.id;
              data.eventDate = data.eventDate.toDate();
              data.date = data.date.toDate();
              return data;
            })
            .sort((a: Event, b: Event) => {
              return a.eventDate - b.eventDate;
            });
        })
      );
  }
}
