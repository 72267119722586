import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Article } from '../../../models/article.model';
import { Title } from '@angular/platform-browser';
import { ArticlesService } from 'src/app/services/articles.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-news-single',
  templateUrl: './news-single.component.html',
  styleUrls: ['./news-single.component.css']
})
export class NewsSingleComponent implements OnInit {

  article: Article;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private articlesService: ArticlesService,
    private router: Router,
    private titleService: Title
  ) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.getArticle();
  }
  
  getArticle(): void {
    this.isLoading = true;
    this.route.paramMap.subscribe(params => {
      this.articlesService.getArticle(params.get('url')).pipe(take(1)).subscribe(article => {
        if (article === undefined) {
          this.router.navigate(['/404']);
        }
        this.article = article[0];
        this.titleService.setTitle('Trautsons - ' + this.article.title);
        this.isLoading = false;     
      })
    })
  }

}
