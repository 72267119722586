import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Gallery } from "../../../models/gallery.model";
import galleries from "../../../../assets/json/galleries.json";
import { Title } from "@angular/platform-browser";
import { GalleriesService } from "src/app/services/galleries.service";

@Component({
  selector: "app-gallery-single",
  templateUrl: "./gallery-single.component.html",
  styleUrls: ["./gallery-single.component.css"],
})
export class GallerySingleComponent implements OnInit {
  gallery: Gallery;
  currentImg = 0;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private galleriesService: GalleriesService
  ) {}

  setImg(num: number) {
    this.currentImg = num;
  }

  nextImg() {
    if (this.currentImg !== this.gallery.galleryImgUrls.length - 1) {
      this.currentImg++;
    }
  }

  prevImg() {
    if (this.currentImg !== 0) {
      this.currentImg--;
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.getGallery(params.get("url"));
    });
  }

  getGallery(id: string): void {
    this.isLoading = true;
    this.galleriesService.getGallery(id).subscribe((gallery: Gallery[]) => {
      if (gallery.length === 0) {
        this.router.navigate(["/404"]);
      }
      this.gallery = gallery[0];
      this.titleService.setTitle("Trautsons - " + this.gallery.title);

      this.isLoading = false;
    });
  }
}
