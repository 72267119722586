import { Component, OnInit } from "@angular/core";

import { Event } from "../../models/event.model";
// import events from '../../../assets/json/events.json';
import { Title } from "@angular/platform-browser";
import { EventsService } from "src/app/services/events.service";

@Component({
  selector: "app-fb-events-page",
  templateUrl: "./fb-events-page.component.html",
  styleUrls: ["./fb-events-page.component.css"],
})
export class FbEventsPageComponent implements OnInit {
  events: Event[] = [];
  isLoading: boolean;

  constructor(
    private titleService: Title,
    private eventsService: EventsService
  ) {
    // this.events = events;
    this.isLoading = true;

    // this.events = this.bubbleSort(events);

    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    // for (let i = 0; i < this.events.length; i++) {
    //   const date = new Date(this.events[i].date);
    //   if (date < yesterday) {
    //     this.events.splice(i, 1);
    //     i--;
    //   }
    // }

    titleService.setTitle("Trautsons - Eseményeink");
  }

  ngOnInit() {
    this.getEvents();
  }

  getEvents(): void {
    this.isLoading = true;
    this.eventsService.getEvents().subscribe((events: Event[]) => {

      events.forEach((event) => {
        if (event.eventDate.getTime() >= new Date().getTime()) {
          this.events.push(event);
        }
      });
      // this.events = events;
      console.log(events);

      this.isLoading = false;
    });
  }

  // bubbleSort(arr) {
  //   const n = arr.length;
  //   for (let i = 0; i < n - 1; i++) {
  //     for (let j = 0; j < n - i - 1; j++) {
  //       if (arr[j].date > arr[j + 1].date) {
  //         [arr[j], arr[j + 1]] = this.swap([arr[j], arr[j + 1]]);
  //       }
  //     }
  //   }
  //   return arr;
  // }

  // swap([element1, element2]) {
  //   const tmp = element1;
  //   element1 = element2;
  //   element2 = tmp;

  //   return [element1, element2];
  // }
}
