import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

import { Email } from "../../models/email.model";
import { EmailsService } from "../../services/emails.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact-page.component.html",
  styleUrls: ["./contact-page.component.css"],
})
export class ContactComponent implements OnInit {
  email: Email;
  isBtnLoading: boolean;

  contactFg = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    subject: new FormControl("", [Validators.required]),
    message: new FormControl("", [Validators.required]),
  });

  constructor(
    private titleService: Title,
    private router: Router,
    private emailsService: EmailsService
  ) {
    this.isBtnLoading = false;
    titleService.setTitle("Trautsons - Kapcsolat");
    // this.email = {
    //   id: '',
    //   name: '',
    //   email: '',
    //   subject: '',
    //   message: '',
    //   date: ''
    // };
  }

  onSubmit() {
    this.isBtnLoading = true;
    let email = { ...this.contactFg.getRawValue(), date: new Date() };

    this.emailsService.contactEmail(email).subscribe((res) => {
      this.router.navigate(["/koszonjuk"]);
      this.isBtnLoading = false;
    });
  }

  ngOnInit() {}
}
