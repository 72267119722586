import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import mailTemps from "../../assets/mail-temps.json";

@Injectable({
  providedIn: "root",
})
export class EmailsService {
  private emailEndpoint = "/assets/php-mailer/mailer.php";

  constructor(private http: HttpClient) {}

  contactEmail(message) {
    const email = {
      emailAddress: "trautsons@gmail.com",
      // emailAddress: "rakszabi@gmail.com",
      replyToEmail: message.email,
      replyToName: message.name,
      subject: "Üzenet a Trautsons oldalról",
      body: this.createBodyMessage(message),
    };
    return this.http.post(this.emailEndpoint, email, { responseType: "text" });
  }

  createBodyMessage(datas: any): string {
    let body: string;
    body = mailTemps.HEAD;
    body += `<body> <div class="container block"> <div class="block header-line"></div><img src="https://trautsons.hu/assets/images/trautsons-black.png" alt="Trautsons" class="block header-logo"/> <div class="content"> <h1>Kedves Admin!</h1> <p>Üzenet érkezett a trautsons.hu weboldalról.</p><div class="block details"> <p>Feladó: ${datas.name}</p><p>E-mail cím: ${datas.email}</p><p>Tárgy: ${datas.subject}</p><p>Üzenet: ${datas.message}</p></div></div><div class="block footer-line"> <img src="https://trautsons.hu/assets/images/trautsons-white.png" alt="Trautsons" class="block footer-logo"/> </div></div></body></html>`;

    return body;
  }
}
