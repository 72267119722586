import { Injectable } from '@angular/core';

import { Ticket } from '../models/ticket.model';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  collectionName: string;

  constructor(private firestore: AngularFirestore) {
    this.collectionName = "tickets-oszvaro-x";
  }

  createTicket(ticket: Ticket) {
    return this.firestore.collection(this.collectionName).add(ticket);
  }

  updateTicket(ticket: Ticket) {
    return this.firestore
      .collection(this.collectionName)
      .doc(ticket.id)
      .set(ticket);
  }

  getTickets() {
    return this.firestore
      .collection(this.collectionName)
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes
            .map((a) => {
              const data = a.payload.doc.data() as Ticket;
              data.date = data.date.toDate();
              data.id = a.payload.doc.id;
              return data;
            })
            .sort((a: Ticket, b: Ticket) => {
              const date1 = new Date(a.date);
              const date2 = new Date(b.date);
              return date2.getTime() - date1.getTime();
            });
        })
      );
  }

  setToUsed(item: Ticket) {
    item.isUsed = true;
    this.updateTicket(item);
  }

}
