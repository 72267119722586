import { Component, OnInit } from "@angular/core";
import { Member } from "../../models/member.model";
// import members from '../../../assets/json/members.json';
import { Title } from "@angular/platform-browser";
import { MembersService } from "src/app/services/members.service";

@Component({
  selector: "app-about-us-page",
  templateUrl: "./about-us-page.component.html",
  styleUrls: ["./about-us-page.component.css"],
})
export class AboutUsPageComponent implements OnInit {
  members: Member[];
  isLoading: boolean;

  constructor(
    private titleService: Title,
    private membersService: MembersService
  ) {
    titleService.setTitle("Trautsons - Rólunk");
    this.isLoading = true;
  }

  ngOnInit() {
    this.getMembers();
  }

  getMembers(): void {
    this.isLoading = true;
    this.membersService.getMembers().subscribe((members: Member[]) => {
      this.members = members;
      this.isLoading = false;
    });
  }
}
