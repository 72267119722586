import { Component, OnInit } from "@angular/core";

import { Article } from "../../models/article.model";
// import articles from '../../../assets/json/articles.json';
import { Title } from "@angular/platform-browser";
import { ArticlesService } from "src/app/services/articles.service";

@Component({
  selector: "app-news-page",
  templateUrl: "./news-page.component.html",
  styleUrls: ["./news-page.component.css"],
})
export class NewsPageComponent implements OnInit {
  articleList: Article[] = [];
  filteredArticleList: Article[];
  isLoading: boolean;
  search: string;
  allTags: string[] = [];
  searchedTag: string = "";

  constructor(
    private titleService: Title,
    private articlesService: ArticlesService
  ) {
    this.isLoading = true;
    titleService.setTitle("Trautsons - Aktualitások");
    // this.search = '';
  }

  ngOnInit() {
    this.getArticles();
  }

  getArticles(): void {
    this.isLoading = true;
    this.articlesService.getArticles().subscribe((articles) => {
      articles.forEach((element) => {
        element.tags.forEach((tag) => {
          let isPushable = true;
          this.allTags.forEach((validTag) => {
            if (tag === validTag) {
              isPushable = false;
            }
          });
          if (isPushable) {
            this.allTags.push(tag);
          }
        });
      });
      console.log(articles);

      this.articleList = articles;
      this.filteredArticleList = articles;
      this.isLoading = false;
    });
  }

  searching() {
    this.searchedTag = "";
    if (this.search === "") {
      this.filteredArticleList = this.articleList;
    } else {
      let articlesTmp: Article[] = [];

      this.filteredArticleList.forEach((element) => {
        if (
          element.title.toLowerCase().search(this.search.toLowerCase()) !==
            -1 ||
          element.description
            .toLowerCase()
            .search(this.search.toLowerCase()) !== -1
        ) {
          articlesTmp.push(element);
        }
      });
      this.filteredArticleList = articlesTmp;
    }
  }

  searchTag(searchingTag) {
    this.searchedTag = searchingTag;
    let articlesTmp: Article[] = [];

    this.filteredArticleList.forEach((element) => {
      element.tags.forEach((tag) => {
        if (searchingTag === tag) {
          articlesTmp.push(element);
        }
      });
    });
    this.filteredArticleList = articlesTmp;
  }
}
