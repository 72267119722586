import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'tel'})
export class TelPipe implements PipeTransform {
    transform(value: string): string {

        value = value.split(' ').join('');
        let newTel = '';
        if (!value) {
            newTel = '';
        }
        if (value.length === 1 || value.length === 2) {
            newTel = value;
        }
        if (value.length === 3) {
            newTel = value.toString().substring(0,2) + ' ' + value.toString().substring(2, 3);
        }
        if (value.length === 4) {
            newTel = value.toString().substring(0,2) + ' ' + value.toString().substring(2, 4);
        }
        if (value.length === 5) {
            newTel = value.toString().substring(0,2) + ' ' + value.toString().substring(2, 5);
        }
        if (value.length >= 6) {
            newTel = value.toString().substring(0,2) + ' ' + value.toString().substring(2, 5) + ' ' + value.toString().substr(5);
        }
        
        return newTel;
    }
}