import { Component, OnInit } from "@angular/core";
import { ArticlesService } from "src/app/services/articles.service";

import { Article } from "../../../models/article.model";
// import articles from '../../../../assets/json/articles.json';

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit {
  article: Article;
  isLoading: boolean;

  constructor(private articlesService: ArticlesService) {
    // this.article = articles[0];
    this.isLoading = true;
  }

  ngOnInit() {
    this.getArticle();
  }

  getArticle(): void {
    this.isLoading = true;
    this.articlesService.getArticles().subscribe((articles: Article[]) => {
      this.article = articles[0];
      this.isLoading = false;
    });
  }
}
