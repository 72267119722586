import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { Member } from "../models/member.model";

@Injectable({
  providedIn: "root",
})
export class MembersService {
  collectionName: string;

  constructor(private firestore: AngularFirestore) {
    this.collectionName = "members";
  }

  getMembers() {
    return this.firestore
      .collection(this.collectionName, (ref) => {
        return ref.where("status", "==", "PUBLISHED");
      })
      .snapshotChanges()
      .pipe(
        map((changes: any[]) => {
          return changes
            .map((a) => {
              const data = a.payload.doc.data() as Member;
              data.id = a.payload.doc.id;
              return data;
            })
            .sort((a: Member, b: Member) => {
              return a.order - b.order;
            });
        })
      );
  }

  getMember(id: string) {
    return this.firestore
      .collection(this.collectionName, (ref) => {
        return ref.where("url", "==", id).limit(1);
      })
      .snapshotChanges()
      .pipe(
        map((changes: any[]) => {
          return changes
            .map((a) => {
              const data = a.payload.doc.data() as Member;
              data.id = a.payload.doc.id;
              return data;
            })
            .sort((a: Member, b: Member) => {
              return b.order - a.order;
            });
        })
      );
  }
}
