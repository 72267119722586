import { Component, OnInit, NgModule, LOCALE_ID } from '@angular/core';
import { Ticket } from 'src/app/models/ticket.model';
import { TicketService } from 'src/app/services/ticket.service';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import errors from 'src/app/const/errorMessages.json';

@Component({
  selector: 'app-ticket-buying',
  templateUrl: './ticket-buying.component.html',
  styleUrls: ['./ticket-buying.component.css']
})
export class TicketBuyingComponent implements OnInit {

  errors = errors;

  // GLASS_VALUE = 1000;
  TICKET_VALUE = 6000;
  // BACON_VALUE = 1500;

  isLoading: boolean;

  ticketForm = new FormGroup({
    lastname: new FormControl('', [Validators.required]),
    firstname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    telephone: new FormControl('', [Validators.pattern("^[0-9 ]*$")]),
    isCompany: new FormControl('', [Validators.required]),
    company: new FormControl('', []),
    vat: new FormControl('', []),
    zip: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9][0-9][0-9]')]),
    country: new FormControl('Magyarország', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    street: new FormControl('', [Validators.required]),
    houseNumber: new FormControl('', [Validators.required]),
    comment: new FormControl(''),

    // tastingGlass: new FormControl('', [Validators.pattern('^[0-9]*$')]),
    wineLicense: new FormControl('', [Validators.pattern('^[0-9]*$')]),
    // baconPackage: new FormControl('', [Validators.pattern('^[0-9]*$')]),
  });

  generatedCode: string;
  totalValue: number;

  constructor(
    private itemService: TicketService,
    private cookieService: CookieService
  ) {
    // this.generatedCode = '';
    // TODO: Ide kell majd egy localStorage-ból való beolvasás.
    this.generatedCode = this.cookieService.get('generatedCode');
    this.totalValue = Number(this.cookieService.get('totalValue'));
    if (this.cookieService.get('totalValue') === '') {
      this.totalValue = 0;
    } else {
      this.totalValue = Number(this.cookieService.get('totalValue'));
    }
    this.isLoading = false;
  }

  ngOnInit() {
    this.changeValidators();
  }

  changeValidators(): void {
    this.ticketForm.get('isCompany').valueChanges.pipe(distinctUntilChanged(), debounceTime(50)).subscribe((value) => {
      if (value === 'company') {
        this.ticketForm.get('company').setValidators([Validators.required]);
        this.ticketForm.get('vat').setValidators([Validators.required]);
      } else if (value === 'personal') {
        this.ticketForm.get('company').setValidators([]);
        this.ticketForm.get('vat').setValidators([]);
      }
      this.ticketForm.get('company').updateValueAndValidity();
      this.ticketForm.get('vat').updateValueAndValidity();
    })
  }

  onSubmit() {

    this.isLoading = true;

    this.generatedCode = Math.random().toString(36).substring(2, 8);
    this.totalValue =
    (Number(this.ticketForm.get('wineLicense').value,) * this.TICKET_VALUE)
    // (Number(this.ticketForm.get('baconPackage').value,) * this.BACON_VALUE) +
    // (Number(this.ticketForm.get('tastingGlass').value,) * this.GLASS_VALUE);

    this.cookieService.set('generatedCode', this.generatedCode);
    this.cookieService.set('totalValue', '' + this.totalValue);

    const ticket: Ticket = {
      lastname: this.ticketForm.get('lastname').value,
      firstname: this.ticketForm.get('firstname').value,
      email: this.ticketForm.get('email').value,
      telephone: this.ticketForm.get('telephone').value,
      isCompany: this.ticketForm.get('isCompany').value,
      company: this.ticketForm.get('company').value,
      vat: this.ticketForm.get('vat').value,
      zip: this.ticketForm.get('zip').value,
      country: this.ticketForm.get('country').value,
      city: this.ticketForm.get('city').value,
      street: this.ticketForm.get('street').value,
      houseNumber: this.ticketForm.get('houseNumber').value,
      comment: this.ticketForm.get('comment').value,

      wineLicense: Number(this.ticketForm.get('wineLicense').value),
      // tastingGlass: Number(this.ticketForm.get('tastingGlass').value),
      // baconPackage: Number(this.ticketForm.get('baconPackage').value),
      
      totalValue: this.totalValue,
      generatedCode: this.generatedCode,
      isPayed: false,
      isUsed: false,
      date: new Date(),
    };

    setTimeout(() => {
      this.itemService.createTicket(ticket).then(() => {
        this.isLoading = false;
      });
      // console.log(ticket);
      // this.isLoading = false;
      
    }, 500);
    
  }

  resetCode() {
    this.cookieService.set('generatedCode', '');
    // TO DO: Ide majd kell egy olyan, h a localStorage-et törli
    this.generatedCode = '';
    this.cookieService.set('totalValue', '');
    this.totalValue = 0;
  }

}
