export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAfFMo1q9qlGWgHfDduGOWYFZSN21GD4Zo",
    authDomain: "trautsons-5bd02.firebaseapp.com",
    databaseURL: "https://trautsons-5bd02.firebaseio.com",
    projectId: "trautsons-5bd02",
    storageBucket: "trautsons-5bd02.appspot.com",
    messagingSenderId: "1005368142477",
    appId: "1:1005368142477:web:f78a9524b9983775d090c4",
    measurementId: "G-HN5K9551M4"
  }
};
