import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { environment } from '../environments/environment.prod';
// import { AngularFireModule } from 'angularfire2';
// import { AngularFirestoreModule } from 'angularfire2/firestore';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeroComponent } from './components/hero/hero.component';
import { ContactComponent } from './pages/contact-page/contact-page.component';
import { HomeComponent } from './pages/home-page/home-page.component';
import { AboutUsComponent } from './pages/home-page/about-us/about-us.component';
import { FbEventsComponent } from './pages/home-page/fb-events/fb-events.component';
import { NewsComponent } from './pages/home-page/news/news.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { FbEventsPageComponent } from './pages/fb-events-page/fb-events-page.component';
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { WorkWithUsComponent } from './components/work-with-us/work-with-us.component';
import { NewsSingleComponent } from './pages/news-page/news-single/news-single.component';
import { AboutUsSingleComponent } from './pages/about-us-page/about-us-single/about-us-single.component';
import { GallerySingleComponent } from './pages/gallery-page/gallery-single/gallery-single.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TicketBuyingComponent } from './pages/ticket-buying/ticket-buying.component';
import { TicketUsageComponent } from './pages/ticket-buying/ticket-usage/ticket-usage.component';

import { TicketService } from './services/ticket.service';
import { CookieService } from 'ngx-cookie-service';
import { TelPipe } from './pipes/tel.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './app-material.module';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ContactComponent,
    HomeComponent,
    AboutUsComponent,
    FbEventsComponent,
    HeroComponent,
    NewsComponent,
    AboutUsPageComponent,
    FbEventsPageComponent,
    GalleryPageComponent,
    NewsPageComponent,
    FooterComponent,
    WorkWithUsComponent,
    NewsSingleComponent,
    AboutUsSingleComponent,
    GallerySingleComponent,
    NotFoundComponent,
    TicketBuyingComponent,
    TicketUsageComponent,
    TelPipe,
    ThankYouComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'rolunk', component: AboutUsPageComponent },
      { path: 'rolunk/:url', component: AboutUsSingleComponent },
      { path: 'hirek', component: NewsPageComponent },
      { path: 'hirek/:url', component: NewsSingleComponent },
      { path: 'esemenyek', component: FbEventsPageComponent },
      { path: 'galeria', component: GalleryPageComponent },
      { path: 'galeria/:url', component: GallerySingleComponent },
      { path: 'kapcsolat', component: ContactComponent },
      { path: 'jegyvasarlas', component: TicketBuyingComponent },
      { path: 'jegyvasarlas/:url', component: TicketUsageComponent },
      { path: 'koszonjuk', component: ThankYouComponent },
      { path: '**', component: NotFoundComponent }
    ]),
    BrowserAnimationsModule
  ],
  providers: [
    TicketService,
    CookieService,
    TelPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
